<template>

    <div>
        <!-- 프로젝트 상태 -->

        <!-- 기술인재 회원 (기술인재 본인의 프로젝트 참여 상태), 관리자에서 기술인재의 프로젝트 상세 조회시 -->
        <div v-if="type == '1' || type == '5'" class="show-box prj">
            <div v-if="projectInfo.projectStatus == '4'" class="state-mark pf us">제 안 단 계</div>
            <div v-else-if="projectInfo.projectRecrStatus == '1' && projectInfo.interviewStatusCd == '04'" class="state-mark pf">참 여 중</div>
            <div v-else-if="projectInfo.projectRecrStatus == '2' && projectInfo.interviewStatusCd == '04'" class="state-mark pf_ep">참 여 예 정</div>
            <div v-else-if="projectInfo.projectRecrStatus == '9' && projectInfo.interviewStatusCd == '04'" class="state-mark pf cp">참 여 완 료</div>

            <nbsp/><div v-if="projectInfo.recrStartYyyymmdd <= nowYyyymmdd && projectInfo.recrEndYyyymmdd >= nowYyyymmdd" class="state-mark ep">+모 집 중</div>
        </div>

        <!-- 수요기업 총괄관리자, 프로젝트 담당자, 관리자 (프로젝트 수행 상태) -->
        <div v-else-if="type == '2' || type == '3' || type == '101' || type == '104' "  class="show-box prj">
            <div v-if="projectInfo.projectStatus == '1'" class="state-mark pf">수 행 중</div>
            <div v-else-if="projectInfo.projectStatus == '2'" class="state-mark pf_ep">수 행 예 정</div>
            <div v-else-if="projectInfo.projectStatus == '9'" class="state-mark pf cp">수 행 완 료</div>	
            <div v-else-if="projectInfo.projectStatus == '4'" class="state-mark pf us">제 안 단 계</div>

            <!-- 수요기업 프로젝트 담당자일때 모집중 표시 -->
            <nbsp/>
            <div v-if="(type == '101' || type == '104') && (projectInfo.apprYn == 'N')" class="state-mark no">미 승 인</div>
            <div v-else-if="(type == '3' || type == '2' || type == '101' || type == '104') && (projectInfo.recrStartYyyymmdd <= nowYyyymmdd && projectInfo.recrEndYyyymmdd >= nowYyyymmdd)" class="state-mark ep">+모 집 중</div>
           
            <div v-if="(type == '101' || type == '104')" class="state-mark" style="width: 230px; float: right; margin-right: 35px;">프로젝트 일련번호 : {{projectInfo.projectSeq}}</div>
       
        </div>

        <!-- 공급기업 프로젝트 현황의 프로젝스 상세 - 기술인재 회원의 참여상태 표시 -->
        <div v-if="type == '4'" class="show-box prj">
            <div v-if="projectInfo.projectStatus == '4'" class="state-mark pf us">제 안 단 계</div>
            <div v-else-if="projectInfo.projectStatus == '1'" class="state-mark pf">참 여 중</div>
            <div v-else-if="projectInfo.projectStatus == '2'" class="state-mark pf_ep">참 여 예 정</div>
            <div v-else-if="projectInfo.projectStatus == '9'" class="state-mark pf cp">참 여 완 료</div>

            <nbsp/><div v-if="projectInfo.recrStartYyyymmdd <= nowYyyymmdd && projectInfo.recrEndYyyymmdd >= nowYyyymmdd" class="state-mark ep">+모 집 중</div>
        </div>      
        
        <!-- 수요/공급기업  프로젝트 지원/인터뷰 현황의 프로젝스 상세 - 프로젝트 상태 표시 -->
        <div v-if="type == '6' || type == '7' || type == '102' || type == '103'" class="show-box prj">
            <!-- <div v-if="projectInfo.projectStatus == '4'" class="state-mark pf us">제 안 단 계</div>
            <div v-else-if="projectRecrStatus == '1'" class="state-mark pf">수 행 중</div>
            <div v-else-if="projectRecrStatus == '2'" class="state-mark pf_ep">수 행 예 정</div>
            <div v-else-if="projectRecrStatus == '9'" class="state-mark pf cp">완료</div> -->

            <div v-if="projectInfo.projectStatus == '4'" class="state-mark pf us">제 안 단 계</div>
            <div v-else-if="projectInfo.projectStatus == '1'" class="state-mark pf">수 행 중</div>
            <div v-else-if="projectInfo.projectStatus == '2'" class="state-mark pf_ep">수 행 예 정</div>
            <div v-else-if="projectInfo.projectStatus == '9'" class="state-mark pf cp">완료</div>

            <nbsp/><div v-if="projectInfo.recrStartYyyymmdd <= nowYyyymmdd && projectInfo.recrEndYyyymmdd >= nowYyyymmdd" class="state-mark ep">+모 집 중</div>
                   <div v-else class="state-mark pf cp">모집완료</div>
        </div>

        <!-- 프로젝트명 -->
        <div class="prj_outline">
            <div class="name" v-if="isScoPrj">
                <div class="label sco" style="vertical-align: top;">공급기업 지정</div><div class="value name" >{{projectInfo.projectNm}}</div>
            </div>
            <div class="name" v-else>
                <div class="label" style="vertical-align: top;">프로젝트명</div><div class="value name" >{{projectInfo.projectNm}}</div>
            </div>

            <div class="outline">
                <div class="label ivl03" style="margin-right: 22px;">개요</div><div class="value multiText" style="line-height: 18px;">{{projectInfo.projectDtlDesc}}</div>
            </div>

            <div class="outline">
                <div class="label ivl04" style="margin-right: 46px;">고객사</div><div class="value multiText" style="line-height: 18px;">{{projectInfo.clientNm}}</div>
            </div>

            <div class="outline">
                <div class="label">산 업 분 야</div><div class="value"><SelectComp type="text" cdId="PRO105" :value="projectInfo.projectIndtype"/> / <SelectComp type="text" cdId="PRO143" :value="projectInfo.projectIndtypeClassCd"/></div>
            </div>

            <div class="date">
                <div class="label ivl01">등 록 일 자</div><div class="value">{{projectInfo.regYyyymmdd}}</div>
            </div>
            
            <div class="date">
                <div class="label">모집마감일</div>
                <div class="value">
                    <template v-if="recrCloseYn != 'Y'">
                    {{projectInfo.recrEndYyyymmdd | date('yyyy.MM.dd')}}
                    </template>
                    <div v-else class="deadline-text">모집마감!</div>
                </div>
            </div>

            <div class="btn-box">

                <div class="btn" style="margin-left:10px"><img src="/images/tec/prj/list_view.png" alt="목록보기" @click="$emit('goList')"/></div>
                <template v-if="editable">
                    <div v-if="type == '3'" class="btn" @click="goModifyProject()"><img src="/images/prj_change.png" alt="프로젝트 수정하기"/></div>
                    <div v-if="type >= 100" class="btn" @click="goAdmModifyProject()"><img src="/images/prj_change.png" alt="프로젝트 수정하기"/></div>
                    <div v-if="isScoPrj && type <= 100 && type!= '6'" class="btn" @click="goScoModifyProject()"><img src="/images/prj_change.png" alt="프로젝트 수정하기"/></div>
                </template>
                
                <template v-if="type == '5'">
                    <div v-if="projectInfo.interestYn == 'Y'" class="btn" @click="deleteInterestProject()"><img src="/images/int_aft_btn.png" alt="관심프로젝트클릭후"/></div>
                    <div v-else class="btn" @click="insertInterestProject()"><img src="/images/int_prv_btn.png" alt="관심프로젝트클릭전"/></div>

                    <div v-if="(projectInfo.projectAppSeq != undefined && projectInfo.projectAppSeq != '') || (projectInfo.projectInterviewIng != undefined && projectInfo.projectInterviewIng != '')" class="btn"><img src="/images/prj_cp_btn.png" alt="프로젝트지원완료"/></div>
                    <div v-else class="btn" @click="insertApp()"><img src="/images/prj_sp_btn.png" alt="프로젝트지원하기"/></div>
                </template>

                <div class="btn">
                    <div class="ci_box">
                        <ImageComp type="image" :src="'/api/prj/tec/projectCorpImg?projectSeq='+projectInfo.projectSeq" defaultSrc="COMPANY" alt="고객사 로고"/>
                    </div>
                </div>
                
            </div>
        </div>
        <!-- 프로젝트 개요 -->      
        <div class="prj_outline">
            <div class="outline">
                <div class="label ivl02">수 행 사</div><div class="value">
                    <span v-if="isScoPrj">{{projectInfo.parentCorpNm}}</span>
                    <span v-else>{{projectInfo.corpNm}}</span>
                </div>
            </div>
            <div class="outline">
                <div class="label ivl02">근 무 지</div><div class="value">{{projectInfo.projectLocCont}}</div>
            </div>
            <div class="outline">
                <div class="label ivl02">담 당 자</div>
                <div v-if="projectInfo.corpMgrMberNm && !isScoPrj" class="value">{{projectInfo.corpMgrMberNm}}</div>
                <div v-else-if="projectInfo.parentCorpMgrMberNm && isScoPrj" class="value">{{projectInfo.parentCorpMgrMberNm}}</div>
                <div v-else class="value">-</div>

                <div v-if="type == 2" class="change" @click="clickChangeCorpMgrMberNm()">변경 <div class="triangle"></div></div>
                <div v-if="type >= 100 && $store.state.userInfo.mberDivCd == '00'" class="change" @click="clickChangeCorpMgrMberNmAdm()">담당자 교체 &#9654;</div>
            </div>

            <div class="outline">
                <div class="label" style="margin-right: 47px;">프로젝트기간</div><div class="value">{{projectInfo.projectStartEndYyyymmdd}}</div>
            </div>
            
            <!-- 기술인재, 수요기업 총관관리자, 공급기업 -->
            <div v-if="type == '1' || type == '2' || type == '4'" class="btn-box notice">
                <div class="btn" v-if="projectInfo.recrEndDateDiff >= 0"><img src="/images/recruit_deadline.png" alt="모집마감일"/><p>모집 마감 <span class="num">{{projectInfo.recrEndDateDiff}}</span>일 전</p></div>
                <div class="btn" style="margin-left : 10px"><img src="/images/tec/prj/prj_member.png" alt="프로젝트 참여자수"/><p>프로젝트 참여 <span class="num">{{projectInfo.mberCnt}}명</span></p></div>
            </div>
            <!-- 수요기업 프로젝트 담당자, 기술인재 프로젝트 찾기, 관리자 -->
            <div v-if="type == '3' || type == '5' || type == '103' || type == '104'" class="btn-box notice">
            
                <div class="btn" v-if="projectInfo.recrEndDateDiff >= 0"><img src="/images/recruit_deadline.png" alt="모집마감일"/><p>모집 마감 <span class="num">{{projectInfo.recrEndDateDiff}}</span>일 전</p></div>
                <div class="btn" v-if="type < 100 && projectInfo.projectStatus != '9' && projectInfo.recrEndDateDiff >= 0" style="margin-left:10px;">
                    <img src="/images/prj_member.png" alt="지원자수"/><p>지원자 <span class="num">{{projectInfo.appCount}}</span>명</p>
                    <!-- <div v-if="type == '3'" class="sp_hr_btn" @click="goAppMber()">지원인재 보기 &#9654;</div> -->
                </div>

                <div v-if="type == '3' && isScoPrj" class="btn member" style="margin-left : 10px">
                    <img src="/images/btn_frame.png" alt="멤버수"/>
                    <div class="number">
                        <div class="cnt-box"><p class="title ivl21">합격</p><p class="person">{{projectInfo.mberCnt}}명</p></div>  <!-- 합격 -->
                        <div class="cnt-box"><p class="title">불합격</p><p class="person">{{projectInfo.mberCntFail}}명</p></div> <!-- 불합격 -->
                    </div>
                </div>

                <div class="btn member" v-if="type == '103' || type == '104'" style="margin-left : 10px">
                    <img src="/images/btn_member.png" alt="멤버수"/>
                    <div class="number">
                        <p>{{projectInfo.passMberCnt}}명</p> <!-- 투입 제외 합격 -->
                        <!--<p>{{projectInfo.mberCnt}}명</p> --> <!-- 합격 -->
                        <p>{{projectInfo.mberCntFail}}명</p>  <!-- 불합격 -->
                        <p>{{projectInfo.mberCntInterview}}명</p>  <!-- 인터뷰 -->
                        <p>{{projectInfo.inputMberCnt}}명</p> <!-- 투입 -->
                        <!-- <p>{{projectInfo.mberCntIng}}명</p> -->  <!-- 투입 -->
                    </div>
                </div>
            </div>

            <!-- 관리자 -->
            <div v-if="type == '101' || type == '102'" class="btn-box notice">
                <div class="btn" v-if="projectInfo.recrEndDateDiff >= 0"><img src="/images/recruit_deadline.png" alt="모집마감일"/><p>모집 마감 <span class="num">{{projectInfo.recrEndDateDiff}}</span>일 전</p></div>
                <div class="btn" style="margin-left : 10px"><img src="/images/prj_member.png" alt="참여인재수"/><p>참여인재 <span class="num">{{projectInfo.mberCnt}}</span>명</p></div>
            </div>

        </div>

        <!-- 지정 공급기업 -->
        <!-- <div class="prj_outline" v-if="projectInfo.projectDivCd == '31' || projectInfo.projectDivCd == '32'">
            <div class="outline">
                <div class="label" style="margin-right:28px">지정된 공급기업</div>
                <div class="value">
                    <div class="dutyList">
                        <span class="dutyitem" v-for="(corpList, index) in projectChoiceCorpList" :key="index">
                            <span>{{corpList.corpNm}}</span>
                        </span>
                    </div>
                </div>
            </div>
        </div> -->

        <!-- 공급사 (프로젝트 등록한 공급기업) -->
        <!-- <div class="prj_outline" v-if="projectInfo.projectDivCd == '31' || projectInfo.projectDivCd == '32'"> -->
        <div v-if="isScoPrj" class="prj_outline" >   
            <div class="outline">
                <div :class="type == '104'? 'label ivl04' : 'label ivl02'" :style="type == '104'? 'margin-right:13px' : 'margin-right:57px'">공 급 사</div>
                <div class="value">
                    <div class="dutyList">
                        <span class="dutyitem">
                            <span>{{projectInfo.corpNm}}</span>
                        </span>
                    </div>
                </div>
            </div>
            <div v-if="type == '104'" class="outline">
                <div class="label" style="margin-right: 31px;">공급기업 담당자</div>
                <div class="value">{{projectInfo.corpMgrMberNm}}</div>
            </div>
        </div>

        <!-- 프로젝트 직무분야 -->
        <div class="prj_outline">
            <div class="outline">
                <!-- <div class="label">직 무 분 야</div><div class="value"><SelectComp type="text_comma_split" cdId="PRO142" :value="projectInfo.dutyFieldCont"/></div> -->
                <div class="label">직 무 분 야</div>
                <div class="value">
                    <div class="dutyList">
                        <span class="dutyitem" v-for="(duty, index) in projectInfo.dutyFieldList" :key="index">
                            <SelectComp type="text_comma_split" cdId="PRO141" :value="duty.dutyFilter"/> / <SelectComp type="text_comma_split" cdId="PRO142" :value="duty.dutyDetail"/>
                        </span>
                    </div>
                </div>
                <!-- <div class="value list">
                    <ul class="duty-list">
                        <li v-for="(duty, index) in projectInfo.dutyFieldList" :key="index">
                            <SelectComp type="text_comma_split" cdId="PRO141" :value="duty.dutyFilter"/> / <SelectComp type="text_comma_split" cdId="PRO142" :value="duty.dutyDetail"/>
                        </li>
                    </ul>
                </div> -->
                <!-- <div class="list">
                    <ul class="duty-list">
                    <li><span> 어플리케이션개발 / 백엔드</span></li>
                    <li><span> 어플리케이션개발 / UI/UX기획</span></li>
                    <li><span> 어플리케이션개발 / 아키텍트</span></li>
                    <li><span> 웹디자인 / HTML</span></li>
                    </ul>
                </div> -->
            </div>
        </div>

        <!-- 프로젝트 모집내용 -->
        <div class="prj_outline">
            <!-- <div class="outline">
                <div class="label">직 무 분 야</div><div class="value"><SelectComp type="text_comma_split" cdId="PRO142" :value="projectInfo.dutyFieldCont"/></div>
            </div> -->
            <div class="outline">
                <div class="label">개 발 기 술</div><div class="value multiText">{{projectInfo.devTechCont}}</div>
            </div>

            <!-- 모집분야 인원자격 -->
            <div v-for="(recr, index) in projectRecrFieldList" :key="recr.projectRecrFieldSeq" class="outline ranking">
                <div :class="index == 0 ? 'label qlif' : 'label qlif hide'">모 집 분 야<br/>인원 / 자격</div>
                <div class="value ranking_tit">&#9660; <span class="mem">{{recr.workCont}} : {{recr.persCnt}}명 </span>
                    &lt; 모집기간 : <span class="period">{{recr.projectRecrStartEndYyyymmdd}}</span> &gt;
                </div><br/>
                <div class="value ranking">
                    <div class="level">기술등급 <span class="lev"><SelectComp type="text" cdId="PRO106" :value="recr.techGradeCd"/></span></div>
                    <div class="career">경력 <span class="cre"><SelectComp type="text" cdId="PRO107" :value="recr.careerTermCd"/></span></div>
                    <div class="period">참여기간 <span class="prd">{{recr.projectJoinStartEndYyyymmdd}}</span></div>
                    
                    <!-- 관리자가 아닐때 -->
                    <div v-if="type < 100 && (recr.recrEndYn == 'Y' || recr.recrTermEndYyyymmdd < nowYyyymmdd)" class="deadline-text">모집마감!</div>
                    <!--// -->

                    <!-- 관리자 -->
                    <div v-if="type >= 100 && (recr.recrEndYn == 'Y' || recr.recrTermEndYyyymmdd < nowYyyymmdd)" class="deadline-text ep" @click="admRecrEnd(1, recr.recrEndYn, recr.projectRecrFieldSeq, recr.workCont, recr.projectRecrStartEndYyyymmdd)">모집마감!</div>
                    <div v-else-if="type >= 100 && editable" class="deadline-text change" @click="admRecrEnd(2, recr.recrEndYn, recr.projectRecrFieldSeq, recr.workCont, recr.projectRecrStartEndYyyymmdd)">모집마감으로 변경 &#9654;</div>
                    <!--//  -->

                </div>
            </div>
        </div>

        <!-- 매니저 -->
        <!-- <div v-if="type >= 100" class="prj_outline agent"> -->
        <div v-if="!isScoPrj" class="prj_outline agent">
            <div class="outline">
                <div class="label">매 니 저</div>
                <!-- <div v-if="projectInfo.admMgrMberNm" class="value">{{projectInfo.admMgrMberNm}}<nbsp/>(<nbsp/>{{projectInfo.admMgrLoginId}}<nbsp/>)</div> -->
                <div v-if="projectInfo.admMgrMberNm" class="value">{{projectInfo.admMgrMberNm}}</div>
                <div v-else class="value">-</div>
                <template v-if="$store.state.userInfo.mberDivCd == '00'">
                    <div v-if="projectInfo.admMgrMberNm" class="change" @click="clickChangeAgent('mod')">매니저 변경 &#9654;</div>
                    <div v-else class="change" @click="clickChangeAgent('new')">매니저 배정 &#9654;</div>
                </template>
            </div>
        </div>        

        <!-- 업무내용 -->
        <div class="prj_outline last">
            <div class="outline">
                <div class="label bold">업 무 내 용</div>
                <nbsp tagName="div" class="busi_inform" :value="projectInfo.projectWorkCont"/>                   
            </div>
        </div>
    </div>
</template>

<script>

/**
 * props :
 *          type : 
 *                  1 - 기술인재 회원 내프로젝트의 프로젝트 상세 팝업
 *                  2 - 수요기업 총괄관리자 전체프로젝트의 프로젝트 상세
 *                  3 - 수요기업 프로젝트 담당자 내프로젝트의 프로젝트 상세
 *                  4 - 공급기업 프로젝트현황의 프로젝트 상태 (프로젝트 참여상태 코드를 props로 받아서 표시함)
 *                  5 - 기술인재 프로젝트찾기의 프로젝트 상세 팝업
 * 
 *                  101 - 관리자 회원관리 > 수요기업 > 수요기업 정보관리 > 프로젝트 목록 > 프로젝트 상세
 *                  102 - 관리자 회원관리 > 기술인재 > 기술인재 최정 정보 관리 > 기술인재 종합정보 > 참여중인 프로젝트 > 프로젝트 상세보기
 *                  103 - 관리자 회원관리 > 기술인재 > 기술인재 최정 정보 관리 > 기술인재 종합정보 > 참여예정 프로젝트 > 프로젝트 상세보기
 *                  104 - 관리자 프로젝트관리 > 전체프로젝트 > 프로젝트 상세보기
 *                                            > 모집중인 프로젝트 > 프로젝트 상세보기
 */
export default {

    props: {
        projectSeq : {},
        tecMberSeq : {}, 
        projectDivCd : {},
        type : String,
        viewId : String,        // 컴포넌트 호출한 화면ID (프로젝트 수정 페이지로 이동 시 뒤로가기 때문에 필요)
        projectRecrStatus : String,     // 프로젝트 참여 기술인재의 참여 상태 (공급기업 프로젝트현황의 프로젝트 상세에서 사용)
    },

	data() {
		return {		
			projectInfo : {},
			projectRecrFieldList : [],
            projectChoiceCorpList : [],

            nowYyyymmdd : '',

            recrCloseYn : 'N',  // 모집마감 여부
		};
	},
	bforeCreate() {
		// console.log('beforeCreate');
	},
	created() {
		// console.log('created');
	},
	beforeMount() {
		// console.log('beforeMount');
	},
	mounted() {
		// console.log('mounted');
        this.nowYyyymmdd = new Date().format("yyyyMMdd");

		this.getProjectInfo();        
	},
	beforeUpdate() {
		// console.log('beforeUpdate');
	},
	updated() {
		// console.log('update');
	},
	beforeDestroy() {
		// console.log('beforeDestroy');
	},
	destroyed() {
		// console.log('destroyed');
	},

	methods: {
		getProjectInfo() {

			var input = {
				projectSeq : this.projectSeq,
                tecMberSeq : this.tecMberSeq,
                type : this.type
			}

			this.$.httpPost('/api/prj/getProjectInfo', input)
				.then(res => {
					// console.log(res.data);
					this.projectInfo 			= res.data.projectInfo;
                    this.projectInfo.projectIndtype = this.projectInfo.projectIndtypeClassCd.substr(0,1);
					this.projectRecrFieldList 	= res.data.projectRecrFieldList;

                    // 모집마감 여부 체크
                    if(this.projectInfo.recrEndYyyymmdd && this.projectInfo.recrEndDateDiff >= 0) {
                        // 모집마감 아님
                        this.recrCloseYn = 'N';
                    } else {
                        // 모집마감
                        this.recrCloseYn = 'Y';
                    }

                    // 콤마 구분자의 직무분야를 리스트로 변환
					if(this.projectInfo.dutyFieldCont) {
						var tempArr = this.projectInfo.dutyFieldCont.split(',');
						//console.log("tempArr : " + tempArr);
						this.projectInfo.dutyFieldList = [{}];					
						for(var i in tempArr) {
							var test = {};
							this.projectInfo.dutyFieldList.push(test);
							this.projectInfo.dutyFieldList[i].dutyDetail = tempArr[i];
                            this.projectInfo.dutyFieldList[i].dutyFilter = tempArr[i].substr(0,2);
						}
						this.projectInfo.dutyFieldList.pop();
					}

					// window.scroll(0, 0);

                    this.$emit('input', this.projectInfo.projectNm);
                    if(this.projectInfo.choiceCorpList != undefined) {
                        this.getChoiceScoList(this.projectInfo.choiceCorpList);
                    }
				})
				.catch(err => {
					// console.log('============================='); 
					// console.log(err);
					// console.log(err.response);
					// console.log('============================='); 

					alert(err.response.data.error_description);
				});
		},

        getChoiceScoList(choiceCorpList) {            
            
            // 지정공급기업목록 변환
            var param = {
                corpSeqList : choiceCorpList.split(',')
            }

            this.$.httpPost('/api/prj/getChoiceScoList', param)
                .then(res => {
                    this.projectChoiceCorpList = res.data.choiceCorpList;
                }).catch(this.$.httpErrorCommon);

        },

		clickChangeCorpMgrMberNm() {
			var param = {
				projectSeq : this.projectInfo.parentProjectSeq == undefined ? this.projectInfo.projectSeq : this.projectInfo.parentProjectSeq,
			};

            if(this.projectInfo.parentProjectSeq != undefined) {
                param.parentProjectYn = 'Y';
            }

			this.$.popup('/dco/gmg/prj/PRJ201P01', param, {width:0, height:0})
				.then(() => {
					this.getProjectInfo();
				})
				// .catch(err => {})
				;
		},

        clickChangeCorpMgrMberNmAdm() {

            
            // if(this.projectInfo.projectDivCd.substr(0,1) == '3') viewNm = 'PRJ920M04';
            
            
			var params = {
				projectSeq : this.projectSeq,
                projectNm : this.projectInfo.projectNm,
				corpSeq : this.projectInfo.corpSeq,
                corpNm : this.projectInfo.corpNm,
                				 
                currCorpMgrMberSeq : this.projectInfo.corpMgrMberSeq,    // 현재 수요기업 프로젝트 담당자
                currCorpMgrMberNm : this.projectInfo.corpMgrMberNm,
                currCorpMgrLoginId : this.projectInfo.corpMgrLoginId,
                
				caller : {
					name: this.$route.name,
					params: this.projectSeq
				}
			}


            var viewNm = 'PRJ910M04';

            // 공급기업지정 프로젝트일 경우, 부모프로젝트를 등록한 수요기업 프로젝트 담당자의 정보 대입
            if(this.isScoPrj) {
                viewNm = 'PRJ920M04'
                params.projectSeq = this.projectInfo.parentProjectSeq
                params.corpSeq = this.projectInfo.parentCorpSeq
                params.corpNm = this.projectInfo.parentCorpNm

                params.currCorpMgrMberSeq = this.projectInfo.parentCorpMgrMberSeq 
                params.currCorpMgrMberNm = this.projectInfo.parentCorpMgrMberNm
                params.currCorpMgrLoginId = this.projectInfo.parentCorpMgrLoginId
                params.parentProjectYn = 'Y'
            }    

			// this.$router.push({name: 'PRJ910M04', params });
            this.$router.push({name: viewNm, params });


        },

        clickChangeAgent(gubun) {
           
           var params = {

				projectSeq : this.projectSeq,
				projectNm : this.projectInfo.projectNm,
                currAdmMgrMberSeq : this.projectInfo.admMgrMberSeq,
                currAdmMgrMberNm : this.projectInfo.admMgrMberNm,
                currAdmMgrLoginId : this.projectInfo.admMgrLoginId,
				gubun : gubun,

				caller : {
					name: this.$route.name,
					params: this.input
				}
			}

			this.$router.push({name: 'PRJ910M06', params });
           
        },

		// 프로젝트 수정
		goModifyProject() {
            if(!this.editable) return;

            if(this.projectInfo.projectDivCd.substr(0,1) == '3') {
                this.$router.push({name : 'PRJ303M02', query : {projectSeq : this.projectSeq}, params : {caller : { name : this.viewId}}});
            } else {
                this.$router.push({name : 'PRJ303M03', query : {projectSeq : this.projectSeq}, params : {caller : { name : this.viewId}}});
            }
			
		},

        // 프로젝트 수정
		goScoModifyProject() {
            if(!this.editable) return;
            // console.log(this.projectInfo);
			this.$router.push({name : 'PRJ406M02', query : {projectSeq : this.projectSeq}, params : { projectNm : this.projectInfo.projectNm, corpNm : this.projectInfo.parentCorpNm, caller : { name : this.viewId}}});
		},

        // 지원인재 보기
        goAppMber() {
            if(this.projectInfo.choiceCorpList == undefined) {
                // this.$router.push({name : 'PRJ305M01', query : {projectSeq : this.projectSeq}, params : {srchName : this.projectInfo.projectNm, caller : { name : this.viewId}}});
                this.$router.push({name : 'PRJ305M02', params : { projectSeq : this.projectSeq, caller : { name : this.$route.name, params :this.input}}});
            } else {
                this.$router.push({name : 'PRJ307M01', params : {srchName : this.projectInfo.projectNm, caller : { name : this.viewId}}});
            }
            
        },

        // 관심프로젝트 등록
        insertInterestProject() {

            this.$.popup('/tec/prj/PRJ101P01', { projectSeq : this.projectInfo.projectSeq, projectNm: this.projectInfo.projectNm, gubun: 'insert', recrCloseYn : this.recrCloseYn})
                .then(res => {
                    if(res) {
                        this.getProjectInfo();
                    }
                });
        },

        // 관심프로젝트 삭제
        deleteInterestProject() {

            this.$.popup('/tec/prj/PRJ101P01', { projectSeq : this.projectInfo.projectSeq, projectNm: this.projectInfo.projectNm, gubun: 'delete'})
                .then(res => {
                    if(res) {
                        this.getProjectInfo();
                    }
                });
        },        

        // 프로젝트 지원하기
        insertApp() {
            this.$.popup('/tec/prj/PRJ101P02', { 
                        projectSeq : this.projectInfo.projectSeq, 
                        projectNm: this.projectInfo.projectNm, 
                        projectRecrFieldList: this.projectRecrFieldList, 
                        projectDivCd : this.projectInfo.projectDivCd,
                        projectDtlDesc : this.projectInfo.projectDtlDesc,
                        clientNm : this.projectInfo.clientNm,
                        projectLocCont : this.projectInfo.projectLocCont,
                        corpNm : this.projectInfo.projectDivCd == '31' || this.projectInfo.projectDivCd == '32' ? this.projectInfo.parentCorpNm: this.projectInfo.corpNm,
                        corpMgrMberNm : this.projectInfo.corpMgrMberNm,
                        gubun: 'insert'})
                .then(res => {
                    //console.log(res);
                    if(res === true) {
                        alert("지원이 완료되었습니다.");
                        this.getProjectInfo();
                    } else if(res == "asgReqFalse") {
                        alert("아직 소속사 신청을 하지 않아 지원을 하실 수 없습니다.\n소속사 신청이 승인된 이후 지원해주세요!");
                    }else if(res == "asgApprFalse") {
                        alert("아직 소속사 승인이 되지 않아 지원을 하실 수 없습니다.\n소속사 신청이 승인된 이후 지원해주세요!");
                    }else if(res == "techGradeFalse") {
                        alert("아직 기술등급이 확정되지 않아 지원을 하실 수 없습니다.\n기술등급이 확정된 이후 지원해주세요!\n※기술등급 확정까지 1일~3일 소요됩니다.");
                    }
                });
        },

        // 프로젝트 지원취소
        // deleteApp() {
        //     this.$.popup('/tec/prj/PRJ101P02', {
        //                 projectSeq : this.projectInfo.projectSeq, 
        //                 projectNm: this.projectInfo.projectNm, 
        //                 projectRecrFieldList: this.projectRecrFieldList, 
        //                 projectAppSeq : this.projectInfo.projectAppSeq,
        //                 gubun: 'delete'})
        //         .then(res => {
        //             if(res) {
        //                 this.getProjectInfo();
        //             }
        //         });
        // },

        // 관리자의 프로젝트 수정
        goAdmModifyProject() {
            if(!this.editable) return;
            var params = {
				caller : {
					name: this.$route.name,
					params: this.input
				}
			}

            if(this.isScoPrj){
                this.$router.push({name: 'PRJ920M03', query : {projectSeq : this.projectSeq}, params });
            }else{
                this.$router.push({name: 'PRJ910M03', query : {projectSeq : this.projectSeq}, params });
            }

			
        },

        // 관리자 모집마감 등록, 해제
        // gubun 1:모집마감 상태 (모집마감 해제 처리), 2:모집중상태 (모집마감 등록 처리)
        // recrEndYn : Y 이면 모집마감을 강제로 한 상태
        admRecrEnd(gubun, recrEndYn, projectRecrFieldSeq, workCont, projectRecrStartEndYyyymmdd) {
            if(!this.editable) return;
            if(gubun == '1') {
                if(recrEndYn != 'Y') {
                    alert('모집기간이 종료된 모집분야입니다.\n\n모집마감 해제를 하시려면 프로젝트 수정에서 해당 모집분야의 모집기간을 수정하시기 바랍니다.');    
                    return;
                }
            } 
            
            var param = {
                projectRecrFieldSeq : projectRecrFieldSeq, 
                workCont: workCont, 
                projectRecrStartEndYyyymmdd: projectRecrStartEndYyyymmdd,
                gubun : gubun
            };

            this.$.popup('/adm/prj/PRJ910P01', param)
                .then(res => {
                    if(res) {
                        this.getProjectInfo();
                    }
                });
        },
	},
    computed: {
        editable(){
            return this.$store.state.userInfo.mberDivCd == '00'                             //총괄관리자
                || this.$store.state.userInfo.mberSeq == this.projectInfo.admMgrMberSeq     //담당매니저
                || this.$store.state.userInfo.mberSeq == this.projectInfo.corpMgrMberSeq    //수요기업담당자
        },

        isScoPrj() {
            return this.projectInfo.projectDivCd == '31' || this.projectInfo.projectDivCd == '32' ? true : false;
        }
    }
};
</script>

